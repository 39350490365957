import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import AvatarEditor from 'react-avatar-editor'
import { Box } from '@rebass/emotion'
import { RotateLeftIcon, RotateRightIcon } from 'components/Iconography'
import { ErrorMessage, FormHeader, StyledButton } from 'components/FormStyles'
import {
	FormBox,
	FormBoxCenter,
	FormFlex,
	FormFlexInner,
	FormFlexInnerColumn,
	FormWrapper,
	FormWrapperBox,
} from 'components/FormLayout'
import { FileField, InputField, SelectField, SliderField, SwitchField } from 'components/FormFields'
import { COLORS, FONT_SIZES, MQ } from 'utils/styleHelpers'
import { getInitials } from 'utils/userHelpers'
import { INPUT_WIDTH } from 'utils/formHelpers'
import Loader from 'components/Loader'
import ProfileAvatar from 'components/ProfileAvatar'
import ChangePasswordForm from 'components/ChangePasswordForm'
import states from 'utils/states'
import { userActions, authActions, connection } from 'wrFirebase'
import { FIREBASE, PROFILE_IMAGE_TYPES, PROFILE_IMAGE_SIZE } from 'utils/constants'

const {
	STORAGE: { PROFILE_IMG_FOLDER, BASE_URL },
} = FIREBASE
const { storage } = connection
const { doUpdateUser, doGetUser, validateUsername } = userActions

const { doUpdateEmail } = authActions

const FormFlexInnerColumnBox = styled.div`
	font-size: ${FONT_SIZES.H6};
	text-align: left;
	flex-basis: 80%;
	${MQ.L} {
		font-size: ${FONT_SIZES.H4};
	}
`
const FormFlexRow = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
`
const FormFlexRowCenterBox = styled.div`
	flex-basis: 60%;
`
const FormFlexRowBox = styled.div``
const FormFlexInnerColumnSwitch = styled.div`
	flex-basis: 20%;
`
function AccountProfileForm({ authUser }) {
	const [defaultAvatarThemeIndex, setDefaultAvatarThemeIndex] = useState(0)
	const [email, setEmail] = useState('')
	const [city, setCity] = useState('')
	const [state, setState] = useState('')
	const [zip, setZip] = useState('')
	const [profileImageBuffer, setProfileImageBuffer] = useState(null)
	const [profileImageURL, setProfileImageURL] = useState('')
	const [error, setError] = useState(null)
	const [firstName, setFirstName] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isEditingPhoto, setIsEditingPhoto] = useState(false)
	const [lastName, setLastName] = useState('')
	const [oldEmail, setOldEmail] = useState(null)
	const [prefersUsername, setPrefersUsername] = useState(true)
	const [profilePrivate, setProfilePrivate] = useState(false)
	const [provider, setProvider] = useState('')
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	const [username, setUsername] = useState(null)
	const [oldUsername, setOldUsername] = useState(null)
	const editor = useRef(null)

	useEffect(() => {
		const loadData = async () => {
			if (authUser) {
				setIsLoading(true)
				const userProfile = await doGetUser(authUser.uid)
				const {
					defaultAvatarThemeIndex,
					city,
					email,
					firstName,
					lastName,
					prefersUsername,
					profileImageURL,
					profilePrivate,
					provider,
					username,
					state,
					zip,
				} = userProfile
				if (!username) {
					setError({
						message: `Looks like you don't have a username.  
								Add one and it will show up in all posts and shares.   
								Otherwise first name and last name will be used.`,
					})
				}
				setDefaultAvatarThemeIndex(defaultAvatarThemeIndex)
				setFirstName(firstName)
				setLastName(lastName)
				setCity(city)
				setState(state)
				setZip(zip)
				setOldEmail(email)
				setOldUsername(username || null)
				setEmail(email)
				setProfileImageURL(profileImageURL || null)
				setProfilePrivate(profilePrivate || false)
				setProvider(provider)
				setPrefersUsername(prefersUsername || false)
				setUsername(username || null)
				setIsLoading(false)
			}
		}
		loadData()
	}, [authUser])

	const onCancelEditingPhoto = () => {
		setIsEditingPhoto(false)
	}

	const onFileChanged = event => {
		setError(null)
		const profileImageName = event.target.files[0].name
		const isValidImageFile = PROFILE_IMAGE_TYPES.filter(type => {
			return profileImageName.indexOf(type) !== -1
		})
		if (isValidImageFile.length === 0) {
			// is not a valid file extension (.jpg, jpeg, png)
			setError({ message: 'Make sure your photo is a JPG, PNG or GIF' })
			return
		}
		setProfileImageBuffer(event.target.files[0])
		setIsEditingPhoto(true)
	}

	const onSubmitUserProfile = async () => {
		const { uid } = authUser
		let imagePath = ''
		setIsLoading(true)
		if (profileImageBuffer) {
			// send photo to Google Cloud Storage
			try {
				const imgObj = editor.current.getImageScaledToCanvas()
				const imgBlob = await new Promise(resolve => {
					imgObj.toBlob(blob => resolve(blob), 'image/png', 0.95)
				})

				const storageRef = storage.ref()
				imagePath = `${PROFILE_IMG_FOLDER}/${uid}.png`
				const profileImageRef = storageRef.child(imagePath)
				await profileImageRef.put(imgBlob)
				setProfileImageURL(`${BASE_URL}/${imagePath}`)
			} catch (error) {
				// eslint-disable-next-line
				console.log(error.message)
			}
		}
		try {
			// only check if they didn't change their username
			if (username && oldUsername !== username) {
				const isValid = await isUsernameValid(username)
				if (!isValid) {
					setIsLoading(false)
					return
				}
			}
			const updatedProfileImageURL = profileImageBuffer ? `${BASE_URL}/${imagePath}` : profileImageURL || null
			await doUpdateUser({
				email,
				firstName,
				lastName,
				city,
				state,
				zip,
				id: uid,
				username,
				prefersUsername: prefersUsername || false,
				profilePrivate: profilePrivate || false,
				profileImageURL: updatedProfileImageURL,
			})
			// update the email address for email auth provider
			if (provider === 'email' && email !== oldEmail) {
				await doUpdateEmail(email)
				// email updates
			}
			if (oldUsername !== username) {
				setOldUsername(username)
			}
			setProfileImageBuffer(null)
		} catch (e) {
			setIsLoading(false)
			setError(e)
			throw Error(e)
		}
		setIsLoading(false)
		setIsEditingPhoto(false)
	}

	const onPrefersUsername = () => {
		setPrefersUsername(!prefersUsername)
	}

	const onProfilePrivate = () => {
		setProfilePrivate(!profilePrivate)
	}

	const onScale = event => {
		setScale(parseFloat(event.target.value))
	}

	const onRotateLeft = () => {
		setRotate(rotate - 90)
	}

	const onRotateRight = () => {
		setRotate(rotate + 90)
	}

	const setStateFirstNameInput = event => {
		setFirstName(event.target.value)
	}

	const setStateLastNameInput = event => {
		setLastName(event.target.value)
	}

	const setStateCityInput = event => {
		setCity(event.target.value)
	}

	const setStateStateInput = event => {
		setState(event.target.value)
	}

	const setStateZipInput = event => {
		setZip(event.target.value)
	}

	const setStateEmailInput = event => {
		setEmail(event.target.value)
	}

	const setStateUsernameInput = event => {
		setUsername(event.target.value === '' ? null : event.target.value)
	}

	const clearErrors = () => {
		setError(null)
	}

	const isUsernameValid = async username => {
		const userValidation = await validateUsername(username)
		const { isValid, message } = userValidation
		if (!isValid) {
			setError({ message })
			return false
		}
		return true
	}
	const isInvalid = !username && prefersUsername
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<FormWrapper>
					<FormWrapperBox>
						<FormHeader>
							Profile: {firstName} {lastName}{' '}
						</FormHeader>
						<FormFlex>
							<FormBox>
								<FormFlexInnerColumn>
									<FormFlexInnerColumnSwitch>
										<SwitchField
											checked={prefersUsername}
											onChange={onPrefersUsername}
											tabIndex={-1}
										/>
									</FormFlexInnerColumnSwitch>
									<FormFlexInnerColumnBox>
										{`Use my username for posts and 
												shares.  (Otherwise, your first and last name will be used)`}
									</FormFlexInnerColumnBox>
								</FormFlexInnerColumn>
							</FormBox>
							<FormBox>
								<FormFlexInnerColumn>
									<FormFlexInnerColumnSwitch>
										<SwitchField
											checked={profilePrivate}
											onChange={onProfilePrivate}
											tabIndex={-1}
										/>
									</FormFlexInnerColumnSwitch>
									<FormFlexInnerColumnBox>Keep my Profile Private</FormFlexInnerColumnBox>
								</FormFlexInnerColumn>
							</FormBox>
							<FormBox>
								<FormHeader>Profile Photo</FormHeader>
							</FormBox>

							{isEditingPhoto && (
								<FormBox alignSelf="center">
									<div
										css={css`
											text-align: center;
										`}
									>
										<AvatarEditor
											ref={editor}
											border={50}
											borderRadius={999}
											color={[38, 50, 56, 0.6]}
											height={PROFILE_IMAGE_SIZE}
											image={profileImageBuffer}
											rotate={parseFloat(rotate)} // RGBA
											scale={parseFloat(scale)}
											width={PROFILE_IMAGE_SIZE}
										/>
									</div>
								</FormBox>
							)}
							<FormBox>
								{!isEditingPhoto && (
									<ProfileAvatar
										key={isEditingPhoto.toString()}
										defaultAvatarThemeIndex={defaultAvatarThemeIndex}
										displayText={getInitials({ prefersUsername, username, firstName, lastName })}
										imageFileURL={profileImageURL}
									/>
								)}
							</FormBox>

							{isEditingPhoto && (
								<FormBox>
									<FormFlexRow>
										<FormFlexRowBox
											onClick={onRotateLeft}
											onKeyDown={onRotateLeft}
											role="button"
											tabIndex={-1}
										>
											<RotateLeftIcon
												height={30}
												onClick={onRotateLeft}
												tabIndex={-1}
												width={30}
											/>
										</FormFlexRowBox>
										<FormFlexRowCenterBox>
											<SliderField
												color={COLORS.GREEN}
												defaultValue={1}
												max="2"
												min="0.1"
												onChange={onScale}
												step="0.01"
												tabIndex={-1}
												width={[1]}
												type="range"
											/>
										</FormFlexRowCenterBox>
										<FormFlexRowBox
											onClick={onRotateRight}
											onKeyDown={onRotateRight}
											role="button"
											tabIndex={-1}
										>
											<RotateRightIcon height={30} width={30} />
										</FormFlexRowBox>
									</FormFlexRow>
								</FormBox>
							)}
							{!isEditingPhoto && (
								<FormBoxCenter>
									<FileField onChange={onFileChanged} tabIndex={-1} />
								</FormBoxCenter>
							)}
							{isEditingPhoto && (
								<FormBox>
									<FormFlexInner>
										<StyledButton
											bg={COLORS.GREEN}
											disabled={isInvalid}
											onClick={onCancelEditingPhoto}
											tabIndex={-1}
										>
											Cancel
										</StyledButton>
									</FormFlexInner>
								</FormBox>
							)}
							<FormBox>
								<InputField
									onChange={setStateUsernameInput}
									onFocus={clearErrors}
									placeholder="Optional Username"
									tabIndex={-1}
									type="text"
									value={username || ''}
									width={INPUT_WIDTH}
								/>
							</FormBox>
							<FormBox>
								<InputField
									onChange={setStateFirstNameInput}
									placeholder="First Name"
									value={firstName || ''}
									width={INPUT_WIDTH}
								/>
							</FormBox>
							<FormBox>
								<InputField
									onChange={setStateLastNameInput}
									placeholder="Last Name"
									tabIndex={0}
									value={lastName}
									width={INPUT_WIDTH}
								/>
							</FormBox>
							{email && (
								<FormBox>
									<InputField
										onChange={setStateEmailInput}
										placeholder="email"
										tabIndex={0}
										value={email}
										width={INPUT_WIDTH}
									/>
								</FormBox>
							)}
							<FormBox>
								<InputField
									onChange={setStateCityInput}
									placeholder="City"
									tabIndex={-1}
									value={city || ''}
									width={INPUT_WIDTH}
								/>
							</FormBox>
							<FormBox>
								<SelectField defaultValue={state} onChange={setStateStateInput} tabIndex={-1}>
									{states.map(s => {
										return (
											<option key={s.abbr} value={s.abbr}>
												{s.fullName}
											</option>
										)
									})}
								</SelectField>
							</FormBox>
							<FormBox>
								<InputField
									onChange={setStateZipInput}
									placeholder="Zip"
									tabIndex={-1}
									value={zip}
									width={INPUT_WIDTH}
								/>
							</FormBox>
							<FormBox>
								<FormFlexInner>
									<StyledButton
										bg={COLORS.BLACK}
										disabled={isInvalid}
										onClick={onSubmitUserProfile}
										tabIndex={-1}
									>
										Save
									</StyledButton>
								</FormFlexInner>
							</FormBox>
							{error && (
								<FormBox>
									<ErrorMessage>{error.message}</ErrorMessage>
								</FormBox>
							)}
							{provider === 'email' && (
								<Box>
									<ChangePasswordForm />
								</Box>
							)}
						</FormFlex>
					</FormWrapperBox>
				</FormWrapper>
			)}
		</>
	)
}

AccountProfileForm.propTypes = {
	authUser: PropTypes.object,
}

export default AccountProfileForm
