import Loader from 'components/Loader'
import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import { ErrorMessage, FormHeader, StyledButton } from 'components/FormStyles'
import { FormBox, FormFlexInner } from 'components/FormLayout'
import { InputField } from 'components/FormFields'
import { INPUT_WIDTH } from 'utils/formHelpers'
import { COLORS } from 'utils/styleHelpers'
import { authActions } from 'wrFirebase'

function ChangePasswordForm() {
	const [passwordOne, setPasswordOne] = useState('')
	const [passwordTwo, setPasswordTwo] = useState('')
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = async event => {
		setIsLoading(true)
		try {
			await authActions.doPasswordUpdate(passwordOne)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			setError(error)
		}
	}

	const setPwOneInputState = event => {
		setPasswordOne(event.target.value)
	}

	const setPwTwoInputState = event => {
		setPasswordTwo(event.target.value)
	}

	const isInvalid = passwordOne !== passwordTwo || passwordOne === ''
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<Flex>
					<Box
						css={css`
							width: 100%;
						`}
					>
						<FormHeader>Change Password</FormHeader>
						<form>
							<Flex
								css={css`
									width: 100%;
								`}
								flexDirection="column"
							>
								<FormBox>
									<InputField
										onChange={setPwOneInputState}
										placeholder="New Password"
										type="password"
										value={passwordOne}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setPwTwoInputState}
										placeholder="Confirm Password"
										type="password"
										value={passwordTwo}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<FormFlexInner>
										<StyledButton bg={COLORS.BLACK} disabled={isInvalid} onClick={onSubmit}>
											Reset My Password
										</StyledButton>
									</FormFlexInner>
								</FormBox>
								{error && (
									<FormBox>
										<ErrorMessage>{error.message}</ErrorMessage>
									</FormBox>
								)}
							</Flex>
						</form>
					</Box>
				</Flex>
			)}
		</>
	)
}

export default ChangePasswordForm
