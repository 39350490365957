import React from 'react'
import PropTypes from 'prop-types'
import AccountProfileForm from 'components/AccountProfileForm'
import GlobalLayout from 'components/GlobalLayout'
import { UnauthorizedPage } from 'components/Session'
import { authConditionIsUser } from 'utils/securityHelpers'
import useAuthorization from 'hooks/useAuthorization'
function AccountPage({ location }) {
	const { isAuthorized, isLoading, authUser } = useAuthorization(authConditionIsUser)
	if (!isLoading) {
		return (
			<>

				{isAuthorized ? <GlobalLayout location={location}><AccountProfileForm authUser={authUser} /></GlobalLayout > : <UnauthorizedPage />}

			</>
		)
	}
	return null

}

AccountPage.propTypes = {
	location: PropTypes.object,
}
export default AccountPage
